<template>
  <div class="categories">
    <Loader v-if="isLoading" class="loader" />
    <div class="categories__selected" v-if="selectedCategories.length">
      <div
        class="item"
        :key="index"
        v-for="(item, index) in selectedCategories"
      >
        <div class="item__name">{{ item }}</div>
        <div class="item__remove" @click="removeCategory(item)">
          <i class="icon-close"></i>
        </div>
      </div>
    </div>
    <div class="categories__same" v-if="sameCategories.length">
      <div
        class="item"
        :class="{ main: item.type === 'main_categories' }"
        @click="addCategory(item.name)"
        :key="index"
        v-for="(item, index) in sameCategories"
      >
        <div class="item__name">{{ item.name }}</div>
        <div class="item__add">
          <i class="icon-close"></i>
        </div>
      </div>
    </div>
    <div class="categories__other">
      <div class="form-control">
        <div class="form-control__name">Categories</div>
        <div class="form-control__row">
          <v-combobox
            :items="allCategories.content"
            @input.native="customCategoriesValue($event.target.value)"
            @change="customCategoriesValue($event)"
            dense
            outlined
            ref="customCategoryRef"
          ></v-combobox>
          <button class="btn violet" @click="addCategory(customCategory)">
            Add category
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/global/Loader";

export default {
  name: "SelectCategories",
  components: { Loader },
  props: {
    saveCategory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCategories: [],
      customCategory: "",
      allCategories: [],
      sameCategories: [],
      mainCategories: [],
      isLoading: false,
    };
  },
  async created() {
    await this.fetchCategories();
  },
  watch: {
    async saveCategory(newValue) {
      if (newValue) {
        await this.saveData();
      }
    },
    async selectedCategories(val) {
      if (val.length) {
        await this.getSameCategories(val);
        this.filterSameCategory(val);
      } else {
        this.sameCategories = [...this.mainCategories];
      }
    },
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  methods: {
    ...mapActions("dashboard", [
      "getCustomerCategories",
      "fetchSameCategories",
      "saveCategories",
    ]),
    ...mapActions("clientSelection", ["fetchCustomerInfo"]),
    customCategoriesValue(val) {
      this.customCategory = val;
    },
    async fetchCategories() {
      this.isLoading = true;
      const params = {
        action: "getCategories",
        customerId: this.getCurrentCustomer.id,
      };
      const data = await this.getCustomerCategories(params);
      this.allCategories = data.find((item) => item.name === "all_categories");
      let findCustomerCategories = data.find(
        (item) => item.name === "customer_categories"
      );
      let mainCategoriesList = data.find(
        (item) => item.name === "main_categories"
      );
      if (mainCategoriesList.content.length) {
        mainCategoriesList.content.forEach((category) => {
          this.mainCategories.push({
            type: "main_categories",
            name: category,
          });
        });
        this.sameCategories.push(...this.mainCategories);
      }
      if (findCustomerCategories.content.length) {
        this.selectedCategories.push(...findCustomerCategories.content);
      }
      this.isLoading = false;
    },
    addCategory(category) {
      if (this.selectedCategories.includes(category)) return;
      if (category.length) {
        this.selectedCategories.push(category);
      }
      this.customCategory = "";
    },
    removeCategory(category) {
      this.selectedCategories = this.selectedCategories.filter(
        (item) => item !== category
      );
    },
    async getSameCategories(categories) {
      this.isLoading = true;
      const params = {
        action: "getSimilarCategories",
        categories: categories,
      };
      const data = await this.fetchSameCategories(params);
      this.sameCategories = [...this.mainCategories];
      if (data.length) {
        data.forEach((item) => {
          this.sameCategories.push({
            type: "similar_categories",
            name: item,
          });
        });
      }
      this.isLoading = false;
    },
    async saveData() {
      this.isLoading = true;
      const params = {
        action: "approveCategories",
        customerId: this.getCurrentCustomer.id,
        categories: this.selectedCategories,
      };
      try {
        await this.saveCategories(params);
        this.$emit("closeModal");
        this.isLoading = false;
        await this.fetchCustomerInfo(this.getCurrentCustomer.id);
      } catch (e) {
        this.isLoading = false;
        throw e;
      }
    },
    filterSameCategory(categories) {
      categories.forEach((selectedItem) => {
        this.sameCategories = this.sameCategories.filter(
          (item) => item.name !== selectedItem
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.categories {
  display: flex;
  flex-direction: column;
  &__other {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 40px;
    .form-control {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &__name {
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 600;
      }
      &__row {
        display: flex;
        align-items: center;
        ::v-deep {
          .v-text-field__details {
            display: none;
          }
          .v-input--dense > .v-input__control > .v-input__slot {
            margin-bottom: 0;
          }
        }
        .btn {
          margin-left: 20px;
          height: 40px;
          border-radius: 4px;
        }
      }
    }
  }
  &__selected {
    margin: 30px -5px 20px -5px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 20px;
    .item {
      margin: 0 5px 10px 5px;
      display: inline-flex;
      align-items: center;
      border-radius: 25px;
      height: 30px;
      padding: 0 10px;
      border: 1px solid $color-violet;
      &__name {
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        margin-right: 10px;
      }
      &__remove {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
  &__same {
    margin: 30px -5px 20px -5px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 20px;
    .item {
      margin: 0 5px 10px 5px;
      display: inline-flex;
      align-items: center;
      border-radius: 4px;
      height: 30px;
      padding: 0 10px;
      background: #ccc;
      position: relative;
      cursor: pointer;
      &.main {
        background: #7e5689;
        color: #fff;
      }
      &__name {
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        margin-right: 10px;
      }
      &__add {
        font-size: 10px;
        line-height: 10px;
        position: relative;
        width: 15px;
        i {
          position: absolute;
          top: -5px;
          right: 3px;
          transform: rotate(45deg);
        }
      }
    }
  }
}
</style>
