<template>
  <div>
    <div class="top-row">
      <v-icon v-if="isShowCloseIcon" small left @click="$emit('close', widget)">
        mdi-close
      </v-icon>
      <div class="top-info">
        <div v-if="isShowTopActions" class="top-info__actions">
          <div class="custom-checkbox">
            <input
              :checked="checkedList.includes(post.id)"
              class="styled-checkbox"
              :id="post.id"
              :value="!checkedList.includes(post.id)"
              type="checkbox"
              @change="approveHandler(!checkedList.includes(post.id), post)"
            />
            <label :for="post.id"></label>
          </div>
          <button
            v-if="isShowEditIcon"
            @click="$emit('edit', $route)"
            class="action-btn"
          >
            <i class="icon-edit"></i>
          </button>
          <button
            v-if="isShowPublishTime"
            class="action-btn"
            @click="$emit('clickOnChangePublishDateIcon')"
          >
            <i class="icon-calendar"></i>
          </button>
          <button
            class="action-btn"
            v-if="
              isShowDeleteSchedulingIcon && post.scheduledForStr.trim() !== ''
            "
            @click="deleteSchedulingHandler(post)"
          >
            <i class="mdi mdi-calendar-remove-outline"></i>
          </button>
          <button
            v-if="isShowDeletedIcon"
            class="action-btn"
            @click="removeDialog.isShow = true"
          >
            <i class="icon-remove"></i>
          </button>
          <button
            v-if="isShowAllowMovePostIcon"
            class="action-btn"
            @click="moveDialog.isShow = true"
          >
            <i class="icon-right push-to-approve"></i>
          </button>
          <button
            class="action-btn"
            v-if="postType === 'approved' && post.media.length"
            @click="downloadMedia(post)"
          >
            <i class="icon-download-media"></i>
          </button>
        </div>
      </div>
    </div>

    <v-card
      class="widget-item"
      :class="{ selected: isShowTopActions && checkedList.includes(post.id) }"
      :color="color"
      max-width="900"
    >
      <slot></slot>
    </v-card>

    <ModalDialog
      :isShow="removeDialog.isShow"
      :title="removeDialog.title"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="removeDialog.footerButtons"
      @success="removePost(widget)"
      @close="removeDialog.isShow = false"
    >
      <template v-slot:description>
        After removing it can not be recovered.
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="moveDialog.isShow"
      :title="moveDialog.title"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="moveDialog.footerButtons"
      @success="movePostHandler(post)"
      @close="moveDialog.isShow = false"
    >
      <template v-slot:description> Are you 100% sure? </template>
    </ModalDialog>
  </div>
</template>

<script>
import ModalDialog from "@/components/global/ModalDialog";

export default {
  name: "Widget",
  data: () => ({
    removeDialog: {
      isShow: false,
      title: "Are you sure to remove the item?",
      footerButtons: [
        { name: "Yes", emitName: "success", btnClass: "violet" },
        { name: "Cancel", emitName: "close" },
      ],
    },
    moveDialog: {
      isShow: false,
      title:
        "You are about to move post to the Approved posts list without Client Approval! ",
      footerButtons: [
        { name: "Yes", emitName: "success", btnClass: "violet" },
        { name: "Cancel", emitName: "close" },
      ],
    },
  }),
  props: {
    color: {
      type: String,
      default: "#DFDFDF",
    },
    icon: {
      type: String,
      default: "",
    },
    widget: {
      type: Object,
      default: () => {},
    },
    isShowCloseIcon: {
      type: Boolean,
      default: true,
    },
    isShowEditIcon: {
      type: Boolean,
      default: false,
    },
    postType: {
      type: String,
      default: "",
    },
    post: {
      type: Object,
      default: () => {},
    },
    isShowTopActions: {
      type: Boolean,
      default: false,
    },
    isShowDateCreation: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      default: "",
    },
    isShowDeletedIcon: {
      type: Boolean,
      default: false,
    },
    isShowPublishTime: {
      type: Boolean,
      default: false,
    },
    isShowAllowMovePostIcon: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    checkedList: {
      type: Array,
      default: () => [],
    },
    isShowDeleteSchedulingIcon: {
      type: Boolean,
      default: false,
    },
  },
  components: { ModalDialog },
  methods: {
    approveHandler(approveFlag, post) {
      this.$emit("approveAction", { approveFlag, postId: post.id, post });
    },
    removePost(widget) {
      this.$emit("delete", widget);
      this.$emit("updateAmountPost");
    },
    movePostHandler(post) {
      this.$emit("acceptToMoveThePost", post);
      this.moveDialog.isShow = false;
    },
    downloadMedia(post) {
      this.$emit("downloadPostMedia", post);
    },
    deleteSchedulingHandler() {
      this.$emit("clearDate", "");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.widget-item {
  background: $color-white !important;
  border: 2px solid #eeeff0 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  box-shadow: none !important;
  padding: 16px 0 !important;
  height: 100%;
  &.selected {
    border: 2px solid #009bff !important;
  }
}
</style>

<style scoped lang="scss">
@import "@/assets/styles/vars";
.top-row {
  position: relative;
  min-height: 28px;
  .v-icon {
    border: 1px solid #eeeff0;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px -2px rgba(31, 35, 37, 0.2);
    background: $color-white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 4px;
    margin-bottom: 7px;
    position: absolute;
    right: 0;
    &:after {
      display: none;
    }
  }
  .mdi-close {
    &:before {
      content: "\e91b";
      font-family: "icomoon" !important;
      color: $color-black;
      font-size: 8px;
      line-height: 9px;
    }
  }
  .top-info {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    &__data {
      font-size: 10px;
      line-height: 140%;
      text-align: right;
      color: rgba(78, 87, 120, 0.6);
    }
    &__actions {
      display: inline-flex;
      align-items: center;
    }
    &__approved {
      font-size: 10px;
      line-height: 140%;
      text-align: right;
      color: rgba(78, 87, 120, 0.6);
      span {
        opacity: 1;
        color: $color-green;
      }
    }
    &__rejected {
      font-size: 10px;
      line-height: 140%;
      text-align: right;
      color: rgba(78, 87, 120, 0.6);
      span {
        opacity: 1;
        color: $color-red;
      }
    }
    .push-to-approve {
      color: green;
    }
  }
  .custom-checkbox {
    .styled-checkbox:checked + label:after {
      top: 4px;
    }
    .styled-checkbox + label:before {
      margin-right: 0;
    }
  }
  .action-btn {
    font-size: 12px;
    line-height: 12px;
    color: $color-blueberry;
    margin-left: 7px;
    .mdi-calendar-remove-outline {
      font-size: 14px;
    }
  }
}

.shareModal {
  padding: 36px;
  position: relative;
  .close {
    position: absolute;
    right: 13px;
    top: 13px;
    font-size: 8px;
    color: $color-black;
    opacity: 0.3;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 28px;
    .icon {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .title {
      font-weight: bold;
      font-size: 24px !important;
      line-height: 140%;
      color: $color-black;
      margin-bottom: 16px;
    }
    .desc {
      font-size: 14px;
      line-height: 160%;
      text-align: center;
      color: #515456;
      a {
        color: $color-violet;
        text-decoration: underline;
      }
    }
  }
  &__footer {
    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        margin: 0 10px;
        min-width: 94px;
      }
    }
  }
}
</style>
