<template>
  <div class="container-wrapper" :class="{ active: widgetPanelIsShow }">
    <v-container fluid>
      <v-row>
        <template v-for="widget in getActiveTabs">
          <v-col cols="12" md="4" :key="widget.id">
            <Widget
              v-if="widget"
              class="widget-container dashboard"
              :widget="widget"
              :isShowCloseIcon="widget.isShowCloseIcon"
              @close="closeWidgetHandler"
              ><template v-if="widget.component">
                <component :is="widget.component" /></template
            ></Widget>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <div class="widget-panel" :class="{ active: widgetPanelIsShow }">
      <div
        class="widget-panel__toggle-btn"
        :class="{ active: widgetPanelIsShow }"
        @click="toggleWidgetPanel"
      >
        <i class="icon-close-menu"></i>
        <span class="text">Widgets</span>
      </div>
      <div
        class="widget-panel__item"
        v-for="tab in getInactiveTabs"
        :key="tab.id"
      >
        <template>
          <button
            class="widget-panel__btn"
            :style="{
              color: tab.color,
              background: tab.background,
              borderColor: tab.borderColor,
            }"
            @click="setActiveFlagById(tab.id)"
          >
            <span class="widget-panel__btn-icon">
              <i :style="{ color: tab.color }" :class="tab.icon"></i>
            </span>
            <span class="widget-panel__btn-name">
              {{ tab.header }}
            </span>
          </button>
        </template>
      </div>
    </div>
    <ModalDialog
      :isShow="categoryDialog.isShow"
      customClass="select-category"
      :size="categoryDialog.size"
      :title="categoryDialog.title"
      :footerButtons="categoryDialog.footerButtons"
      @success="saveCategory = true"
      @cancel="categoryDialog.isShow = false"
      @close="categoryDialog.isShow = false"
    >
      <template v-slot:description>
        <SelectCategories
          @closeModal="
            categoryDialog.isShow = false;
            saveCategory = false;
          "
          :saveCategory="saveCategory"
        />
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="openTasksDialog.isShow"
      customClass="open_tasks"
      :size="openTasksDialog.size"
      :title="openTasksDialog.title"
      :footerButtons="openTasksDialog.footerButtons"
      @success="popupupTasksSuccess"
      @cancel="popupupTasksCancel"
      @close="popupupTasksCancel"
    >
      <template v-slot:description>
        Please note! You have {{ openTasksDialog.openTasksCount }} open Task
        items.
      </template>
    </ModalDialog>
    <Brief @hideBrief="hideBrief()" v-if="briefIsShow" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Widget from "@/components/dashboard/Widget";
import ModalDialog from "@/components/global/ModalDialog";
import SelectCategories from "@/components/dashboard/SelectCategories";
import Brief from "@/components/brief/Index";

export default {
  name: "Index",
  components: {
    Widget,
    ModalDialog,
    SelectCategories,
    Brief,
  },
  data: () => ({
    widgetPanelIsShow: false,
    briefIsShow: false,
    testObject: {
      first: 1,
      second: {
        one: 1,
        two: {
          twoOne: {
            value: 23,
          },
        },
      },
      third: {
        thirdValue: {
          here: {
            value: 1234,
          },
        },
      },
    },
    categoryDialog: {
      isShow: false,
      size: 900,
      title: "Customer Categories",
      footerButtons: [
        { name: "Cancel", emitName: "cancel" },
        { name: "Approve", emitName: "success", btnClass: "violet" },
      ],
    },
    openTasksDialog: {
      isShow: false,
      openTasksCount: 0,
      size: 700,
      title: "Customer Tasks",
      footerButtons: [
        { name: "Cancel", emitName: "cancel" },
        { name: "Show Tasks", emitName: "success", btnClass: "violet" },
      ],
    },
    saveCategory: false,
  }),
  computed: {
    ...mapGetters("dashboard", ["getTabs", "getActiveTabs", "getInactiveTabs"]),
    ...mapGetters("instagramPost", ["getIsStoriesTabActive"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("clientSelection", [
      "getCurrentCustomer",
      "getCustomerShowPopupTasks",
    ]),
  },
  methods: {
    ...mapMutations("dashboard", [
      "setTabs",
      "setActiveFlagById",
      "setInActiveFlagById",
    ]),
    ...mapMutations("post", [
      "refreshCurrentPost",
      "updatePreview",
      "updateNext",
    ]),
    ...mapMutations("mediaLibraryWidget", ["clearMediaLibrarySelections"]),
    ...mapMutations("stories", ["refreshCurrentStories"]),
    ...mapMutations("instagramPost", ["setIsStoriesTabActive"]),
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapMutations("clientSelection", ["setCustomerShowPopupTasks"]),
    closeWidgetHandler(widgetData) {
      this.setInActiveFlagById(widgetData.id);
    },
    toggleWidgetPanel() {
      this.widgetPanelIsShow = !this.widgetPanelIsShow;
    },
    async hideBrief() {
      this.briefIsShow = false;
      this.getCurrentCustomer.show_brief = false;
      try {
        await this.updateCustomer({
          customerId: this.getCurrentCustomer.id,
          params: {
            setShowBrief: true,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    popupupTasksSuccess() {
      this.setCustomerShowPopupTasks(0);
      this.openTasksDialog.isShow = false;
      this.$router.push({ name: "tasks" });
    },
    popupupTasksCancel() {
      this.setCustomerShowPopupTasks(0);
      this.openTasksDialog.isShow = false;
    },
  },
  created() {
    this.setTabs(this.getCurrentUser.read_only);
    if (this.getCustomerShowPopupTasks > 0) {
      this.openTasksDialog.openTasksCount = this.getCustomerShowPopupTasks;
      this.openTasksDialog.isShow = true;
    }
    if (this.getCurrentCustomer.show_brief) {
      this.briefIsShow = true;
    }
  },
  mounted() {
    if (this.getCurrentCustomer.showPopup) {
      this.categoryDialog.isShow = true;
    }
  },
  destroyed() {
    this.refreshCurrentStories();
    this.refreshCurrentPost();
    this.clearMediaLibrarySelections();
    this.setIsStoriesTabActive(false);
    this.updatePreview();
    this.updateNext();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars";
.container-wrapper {
  width: 100%;
  display: flex;
  padding-right: 132px;
  padding-left: 15px;
  transition: all 0.3s ease;
  &.active {
    padding-right: 15px;
    transition: all 0.3s ease;
  }
}
.widget-panel {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 117px;
  padding: 8px;
  background: $color-white;
  &.active {
    width: 0;
    padding: 0;
    .widget-panel {
      &__item {
        transform: scale(0);
      }
    }
  }
  &__toggle-btn {
    position: absolute;
    transform: rotate(-90deg);
    background: $color-white;
    height: 16px;
    top: 45px;
    left: -30px;
    font-weight: 600;
    font-size: 8px;
    color: $color-blueberry;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .text {
      margin-left: 5px;
    }
    i {
      position: relative;
      transform: rotate(-90deg);
    }
    &.active {
      i {
        position: relative;
        transform: rotate(90deg);
      }
    }
    &:before {
      content: "";
      display: block;
      height: 40px;
      width: 16px;
      transform: rotate(90deg);
      background: url("../../assets/img/rectangle-bg-prew.svg") no-repeat 100% 0;
      position: absolute;
      left: -13px;
      top: -12px;
      z-index: -1;
    }
    &:after {
      content: "";
      display: block;
      height: 40px;
      width: 16px;
      transform: rotate(90deg);
      background: url("../../assets/img/rectangle-after-bg.svg") no-repeat 100%
        0;
      position: absolute;
      right: -13px;
      top: -12px;
      z-index: -1;
    }
  }
  &__item {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__btn {
    width: 100%;
    height: 106px;
    border-style: solid;
    border-width: 1px;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 12px;
    background: #ccc;
    padding: 8px;
    transition: all 0.3s ease;
    box-shadow: none;
    &:hover {
      box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
    }
    &-icon {
      width: 28px;
      height: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: $color-white;
      font-size: 14px;
      margin-bottom: 10px;
    }
    &-name {
      font-weight: 600;
      font-size: 11px;
      line-height: 130%;
      text-align: center;
    }
  }
}
.widget-container {
  &.dashboard {
    ::v-deep {
      .top-row {
        min-height: 0;
        height: 2px;
        top: -7px;
        .v-icon {
          position: absolute;
          z-index: 10;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.select-category {
  .modal {
    &__footer {
      .btn-wrapper {
        flex-direction: row;
        justify-content: space-between;
        button {
          max-width: 150px;
        }
      }
    }
  }
}
.open_tasks {
  .modal {
    &__footer {
      .btn-wrapper {
        flex-direction: row;
        justify-content: space-between;
        button {
          max-width: 150px;
        }
      }
    }
  }
}
</style>
